import { useMemo } from 'react'
import {
  BooleanParam,
  DelimitedArrayParam,
  DelimitedNumericArrayParam,
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'

import { QueryParameter } from '@/modules/shared/types/QueryParameter'
import changeDateForQuery from '@/modules/shared/utils/changeDateForQuery'

export interface QueryValues {
  searchValue: string
  status?: string
  selfManaged?: string
  enabled?: string
  fromDate?: string
  toDate?: string
  supplierIds?: (number | null)[] | null
  matchedInvoice?: string | null
  completedReceivingNote?: string | null
  periodYear?: number | null
  periodMonth?: number | null
  categoryIds?: (number | null)[] | null
  bestBuy?: boolean
  supplierProductCode?: string | null
  purchaserProductCode?: string | null
  pPlusProductCode?: string | null
  stockLocations?: (string | null)[] | null
  originStockLocation?: number | null
  destinationStockLocation?: number | null
  countStatus?: (string | null)[] | null
  hideZero?: boolean
  hasQuotes?: boolean
  masterProduct?: boolean | null
  hasImage?: boolean | null
  catalogued?: boolean | null
  matched?: boolean | null
  countedItems?: boolean | null
  requisitionType?: string | null
  deparmentIds?: (number | null)[] | null
  accountIds?: (number | null)[] | null
  destinationIds?: (number | null)[] | null
  ownerIds?: (number | null)[] | null
  organisationUnitIds?: (number | null)[] | null
  preferred?: boolean | null
  id?: string | null
  tradeRelationshipId?: string | null
  apSystemId?: string | null
  purchaserId?: string | null
  supplierId?: string | null
  ownerId?: string | null
  ownerName?: string | null
  verified?: boolean | null
  legalEntity?: boolean | null
  barcode?: string | null
  transferTypes?: (string | null)[] | null
  catalogFilter?: string | null
  alreadyReceived?: boolean
  pplusCode?: string | null
  organisationId?: string | null
  invoiceNumber?: string | null
  creditNoteNumber?: string | null
  catalogId?: string | null
  tradeRelationshipPurchaserId?: string | null
  tradeRelationshipSupplierId?: string | null
  catalogOwnerId?: string | null
  catalogSupplierId?: string | null
  smCatalog?: boolean | null
  contracted?: boolean | null
  common?: boolean | null
  promptType?: string | null
  customCodeEnabled?: boolean | null
}

/**
 * Listen to the query parameters.
 *
 * @returns {QueryValues} An object containing the changed query params with its new value.
 */
export function useWatchQueryParams(): QueryValues {
  const [query] = useQueryParams({
    [QueryParameter.Search]: StringParam,
    [QueryParameter.Status]: withDefault(StringParam, 'all'),
    [QueryParameter.SelfManaged]: withDefault(StringParam, 'all'),
    [QueryParameter.Enabled]: withDefault(StringParam, 'all'),
    [QueryParameter.From]: StringParam,
    [QueryParameter.To]: StringParam,
    [QueryParameter.Suppliers]: DelimitedNumericArrayParam,
    [QueryParameter.MatchedInvoice]: withDefault(StringParam, 'all'),
    [QueryParameter.CompletedReceivingNote]: withDefault(StringParam, 'all'),
    [QueryParameter.RequisitionType]: withDefault(StringParam, 'all'),
    [QueryParameter.PeriodYear]: NumberParam,
    [QueryParameter.PeriodMonth]: NumberParam,
    [QueryParameter.Categories]: DelimitedNumericArrayParam,
    [QueryParameter.BestBuy]: BooleanParam,
    [QueryParameter.SupplierProductCode]: StringParam,
    [QueryParameter.PurchaserProductCode]: StringParam,
    [QueryParameter.PPlusProductCode]: StringParam,
    [QueryParameter.StockLocations]: DelimitedArrayParam,
    [QueryParameter.OriginStockLocation]: NumberParam,
    [QueryParameter.DestinationStockLocation]: NumberParam,
    [QueryParameter.CountStatus]: DelimitedArrayParam,
    [QueryParameter.HideZero]: BooleanParam,
    [QueryParameter.HasQuotes]: BooleanParam,
    [QueryParameter.MasterProduct]: BooleanParam,
    [QueryParameter.Image]: BooleanParam,
    [QueryParameter.Catalogued]: BooleanParam,
    [QueryParameter.Matched]: BooleanParam,
    [QueryParameter.CountedItems]: BooleanParam,
    [QueryParameter.Preferred]: BooleanParam,
    [QueryParameter.Departments]: DelimitedNumericArrayParam,
    [QueryParameter.Accounts]: DelimitedNumericArrayParam,
    [QueryParameter.Destination]: DelimitedNumericArrayParam,
    [QueryParameter.Owner]: DelimitedNumericArrayParam,
    [QueryParameter.OrganisationUnit]: DelimitedNumericArrayParam,
    [QueryParameter.Id]: StringParam,
    [QueryParameter.TradeRelationshipId]: StringParam,
    [QueryParameter.ApSystemId]: StringParam,
    [QueryParameter.PurchaserId]: StringParam,
    [QueryParameter.SupplierId]: StringParam,
    [QueryParameter.OwnerId]: StringParam,
    [QueryParameter.OwnerName]: StringParam,
    [QueryParameter.Verified]: BooleanParam,
    [QueryParameter.LegalEntity]: BooleanParam,
    [QueryParameter.Barcode]: StringParam,
    [QueryParameter.TransferTypes]: DelimitedArrayParam,
    [QueryParameter.CatalogFilter]: StringParam,
    [QueryParameter.AlreadyReceived]: BooleanParam,
    [QueryParameter.PPlusCode]: StringParam,
    [QueryParameter.OrganisationId]: StringParam,
    [QueryParameter.InvoiceNumber]: StringParam,
    [QueryParameter.CreditNoteNumber]: StringParam,
    [QueryParameter.CatalogId]: StringParam,
    [QueryParameter.TradeRelationshipPurchaserId]: StringParam,
    [QueryParameter.TradeRelationshipSupplierId]: StringParam,
    [QueryParameter.CatalogOwnerId]: StringParam,
    [QueryParameter.CatalogSupplierId]: StringParam,
    [QueryParameter.SMCatalog]: BooleanParam,
    [QueryParameter.Contracted]: BooleanParam,
    [QueryParameter.Common]: BooleanParam,
    [QueryParameter.PromptType]: StringParam,
    [QueryParameter.CustomCodeEnabled]: BooleanParam,
  })

  const data: QueryValues = useMemo(
    () => ({
      searchValue: query[QueryParameter.Search] || '',
      status: query[QueryParameter.Status],
      selfManaged: query[QueryParameter.SelfManaged],
      enabled: query[QueryParameter.Enabled],
      fromDate: changeDateForQuery(QueryParameter.From, query[QueryParameter.From]),
      toDate: changeDateForQuery(QueryParameter.To, query[QueryParameter.To]),
      supplierIds: query[QueryParameter.Suppliers],
      matchedInvoice: query[QueryParameter.MatchedInvoice],
      completedReceivingNote: query[QueryParameter.CompletedReceivingNote],
      periodYear: query[QueryParameter.PeriodYear],
      periodMonth: query[QueryParameter.PeriodMonth],
      categoryIds: query[QueryParameter.Categories],
      bestBuy: !!query[QueryParameter.BestBuy],
      supplierProductCode: query[QueryParameter.SupplierProductCode],
      purchaserProductCode: query[QueryParameter.PurchaserProductCode],
      pPlusProductCode: query[QueryParameter.PPlusProductCode],
      stockLocations: query[QueryParameter.StockLocations],
      originStockLocation: query[QueryParameter.OriginStockLocation],
      destinationStockLocation: query[QueryParameter.DestinationStockLocation],
      countStatus: query[QueryParameter.CountStatus],
      hideZero: !!query[QueryParameter.HideZero],
      hasQuotes: !!query[QueryParameter.HasQuotes],
      masterProduct: query[QueryParameter.MasterProduct],
      hasImage: query[QueryParameter.Image],
      catalogued: query[QueryParameter.Catalogued],
      matched: query[QueryParameter.Matched],
      countedItems: query[QueryParameter.CountedItems],
      requisitionType: query[QueryParameter.RequisitionType],
      deparmentIds: query[QueryParameter.Departments],
      accountIds: query[QueryParameter.Accounts],
      destinationIds: query[QueryParameter.Destination],
      ownerIds: query[QueryParameter.Owner],
      organisationUnitIds: query[QueryParameter.OrganisationUnit],
      preferred: query[QueryParameter.Preferred],
      id: query[QueryParameter.Id],
      tradeRelationshipId: query[QueryParameter.TradeRelationshipId],
      apSystemId: query[QueryParameter.ApSystemId],
      purchaserId: query[QueryParameter.PurchaserId],
      supplierId: query[QueryParameter.SupplierId],
      ownerId: query[QueryParameter.OwnerId],
      ownerName: query[QueryParameter.OwnerName],
      verified: query[QueryParameter.Verified],
      legalEntity: query[QueryParameter.LegalEntity],
      barcode: query[QueryParameter.Barcode],
      transferTypes: query[QueryParameter.TransferTypes],
      catalogFilter: query[QueryParameter.CatalogFilter],
      alreadyReceived: !!query[QueryParameter.AlreadyReceived],
      pplusCode: query[QueryParameter.PPlusCode],
      organisationId: query[QueryParameter.OrganisationId],
      invoiceNumber: query[QueryParameter.InvoiceNumber],
      creditNoteNumber: query[QueryParameter.CreditNoteNumber],
      catalogId: query[QueryParameter.CatalogId],
      tradeRelationshipPurchaserId: query[QueryParameter.TradeRelationshipPurchaserId],
      tradeRelationshipSupplierId: query[QueryParameter.TradeRelationshipSupplierId],
      catalogOwnerId: query[QueryParameter.CatalogOwnerId],
      catalogSupplierId: query[QueryParameter.CatalogSupplierId],
      smCatalog: query[QueryParameter.SMCatalog],
      contracted: query[QueryParameter.Contracted],
      common: query[QueryParameter.Common],
      promptType: query[QueryParameter.PromptType],
      customCodeEnabled: query[QueryParameter.CustomCodeEnabled],
    }),
    [query]
  )

  return data
}
