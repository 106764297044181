import { Controller, useFormContext } from 'react-hook-form'

import { Supplier } from '@/graphql/purchase-plus/generated/purchasePlus_graphql'
import { PureSupplierRelationshipsSelection } from '@/modules/shared/components/pure-comboboxes/PureSupplierRelationshipsSelection'
import { Tooltip } from '@/modules/shared/components/tooltip/Tooltip'
import { QuestionMarkIcon } from '@/modules/shared/icons/QuestionMarkIcon'

interface FormSelectSupplierRelationshipProps {
  title: string
  description?: string | JSX.Element
  register: string
  placeholder: string
  required?: boolean
  requiredError?: string
  onSelected?: (value: Supplier) => void
  className?: string
  tooltip?: string
  showChips?: boolean
  defaultValue?: Supplier
  disabled?: boolean
  apSystemId?: number
}

export default function FormSelectSupplierRelationship({
  title,
  description,
  register,
  placeholder,
  required,
  requiredError,
  onSelected,
  className,
  tooltip,
  showChips = true,
  defaultValue,
  disabled,
  apSystemId,
}: FormSelectSupplierRelationshipProps) {
  const {
    control,
    resetField,
    formState: { errors },
    getValues,
  } = useFormContext()

  return (
    <section className={className || 'mt-8'}>
      <h2 className="flex items-center gap-x-1.5 text-sm font-semibold">
        {title}
        {tooltip && (
          <Tooltip content={tooltip}>
            <QuestionMarkIcon className="size-4" />
          </Tooltip>
        )}
      </h2>
      <p className="text-sm text-gray-500">{description}</p>
      <div className="mt-2">
        <Controller
          control={control}
          name={register}
          rules={{ required }}
          render={({ field }) => (
            <PureSupplierRelationshipsSelection
              placeholder={placeholder}
              onSelected={(e) => {
                if (e) {
                  onSelected?.(e)
                  getValues(register) !== e.id && field.onChange(e?.id)
                }
              }}
              hasError={Boolean(errors[register])}
              errorMessage={requiredError}
              onChipRemove={() => resetField(register)}
              showChips={showChips}
              defaultValue={defaultValue}
              disabled={disabled}
              apSystemId={apSystemId}
            />
          )}
        />
      </div>
    </section>
  )
}
