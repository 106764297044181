import { useMutation } from '@apollo/client'
import { produce } from 'immer'

import { CreateContractCataloguedProductDocument } from '@/graphql/purchasing/generated/createContractCataloguedProduct.generated'
import {
  GetAvailableProductsQuery,
  GetCurrentSupplierProductsQuery,
  ProductEdge,
  Supplier,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { GET_AVAILABLE_PRODUCTS } from '@/graphql/purchasing/operations/queries/getAvailableProducts'
import { GET_CURRENT_SUPPLIER_PRODUCTS } from '@/graphql/purchasing/operations/queries/getCurrentSupplierProducts'
import useSessionUser from '@/modules/access/hooks/useSessionUser'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { ProductType } from '@/modules/shared/types/ProductType'

interface CreateContractCataloguedProductProps {
  catalogId?: number
  line?: ProductType
}

export function useCreateContractCataloguedProduct({ catalogId, line }: CreateContractCataloguedProductProps) {
  const { currentOrganisation } = useSessionUser()
  const mutation = useMutation(CreateContractCataloguedProductDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    update(cache, data) {
      if (!!line && !data.data?.createContractCataloguedProduct.errors?.length) {
        const newProduct = data?.data?.createContractCataloguedProduct?.contractCataloguedProduct
        if (currentOrganisation?.isAPurchaser) {
          const queryToCachePurchaserProducts = {
            query: GET_AVAILABLE_PRODUCTS,
            variables: {
              invoiceId: 0,
              requisitionId: 0,
              catalogueId: catalogId,
              nextGenQuotes: false,
              sort: {
                property: 'created_at',
                direction: 'DESC',
              },
            },
          }

          const availablePurchaserProductsCache = cache.readQuery(queryToCachePurchaserProducts)

          const newAvailableProductsCache = produce(
            availablePurchaserProductsCache,
            (draft: GetAvailableProductsQuery) => {
              const { edges } = draft.currentPurchaser?.availableProducts || {}
              if (draft.currentPurchaser && draft.currentPurchaser.availableProducts) {
                draft.currentPurchaser.availableProducts.edges = edges?.map((e) => {
                  if (e?.node?.id === line?.product?.id) {
                    if (e && e.node) {
                      e.node.catalogued = true
                      e.node.availableQuotes?.push({
                        catalogue: {
                          id: Number(newProduct?.catalogueId),
                          __typename: 'Catalogue',
                        },
                        unitPrice: Number(newProduct?.sellUnitPrice),
                        taxPercentage: Number(newProduct?.sellUnitTaxPercentage),
                        supplier: {
                          id: newProduct?.pricedCatalogue?.supplier?.id || 0,
                          name: newProduct?.pricedCatalogue?.supplier?.name || '',
                          __typename: 'Supplier',
                        },
                        contracted: false,
                      })
                    }
                  }
                  return e
                }) as ProductEdge[]
              }
            }
          )
          cache.writeQuery({ ...queryToCachePurchaserProducts, data: newAvailableProductsCache })
        } else if (currentOrganisation?.isASupplier) {
          const queryToCacheSupplierProducts = {
            query: GET_CURRENT_SUPPLIER_PRODUCTS,
            variables: {
              catalogueId: catalogId,
              sort: {
                property: 'created_at',
                direction: 'DESC',
              },
            },
          }

          const availableSupplierProductsCache = cache.readQuery(queryToCacheSupplierProducts)

          const newAvailableProductsCache = produce(
            availableSupplierProductsCache,
            (draft: GetCurrentSupplierProductsQuery) => {
              const { edges } = (draft.currentLegalEntity as Supplier)?.availableProducts || {}
              if (draft.currentLegalEntity && (draft.currentLegalEntity as Supplier).availableProducts) {
                ;(draft.currentLegalEntity as Supplier).availableProducts!.edges = edges?.map((e) => {
                  if (e?.node?.id === line?.product?.id) {
                    if (e && e.node) {
                      e.node.catalogued = true
                    }
                  }
                  return e
                }) as ProductEdge[]
              }
            }
          )
          cache.writeQuery({ ...queryToCacheSupplierProducts, data: newAvailableProductsCache })
        }
      }
    },
  })

  return mutation
}
